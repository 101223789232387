import './product-teaser.scss';

class ProductTeaser {
    constructor (element) {
        // Elements
        this.$root = element;
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.$root.addEventListener('mouseover', () => {
            const $parentContainer = this.$root.parentNode;

            if (this.$root.classList.contains('by-night')) {
                $parentContainer.classList.add('is--by-night');
                $parentContainer.classList.remove('is--by-day');
            } else {
                $parentContainer.classList.remove('is--by-night');
                $parentContainer.classList.add('is--by-day');
            }
        });
    }
}

export { ProductTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-productteaser="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new ProductTeaser($root);
                $rootAPI.initialize();
            });
        }
    }
});
